import React from 'react';
import { Link } from 'gatsby';

import './BlogPostsPreview.styles.scss';

const BlogPostsPreview = ({ blogPosts }) => {
  return (
    <div className="blog-posts-preview">
      {
        blogPosts.reverse().map((post) => {
          return (
            <Link to={`/blog-post?id=${post.id}`} style={{ textDecoration: 'none' }} key={post.id}>
              <div className="blog-posts-preview__preview-tile">
                <div className="blog-posts-preview__img-container">
                  {
                    post.image ?
                      <img className="blog-posts-preview__img" src={post.image.url} alt="Blog post preview photo" />
                       : null
                  }
                </div>

                <div className="blog-post-preview__preview-tile-body">
                  <p className="blog-post-preview__publish-date">
                    { post.formattedDate }
                  </p>
                  <h3 className="blog-post-preview__post-title">
                    { post.title }
                  </h3>

                  <p className="blog-post-preview__text-preview">
                    { post.content.split(' ').slice(0, 23).join(' ') + '...' }
                  </p>
                </div>
              </div>
            </Link>
          )
        })
      }
    </div>
  );
};

export default BlogPostsPreview;
