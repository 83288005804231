import React, { Component } from 'react';
import Strapi from 'strapi-sdk-javascript/build/main';

// Helpers
import { formatBlogPostTimestamp } from '../utils/formatTimestamps';

// Components
import Layout from '../components/Layout';
import SEO from '../components/seo';
import BlogPostsPreview from '../components/BlogPostsPreview';
import BlogPostsLoading from '../components/BlogPostsLoading';

// Styles
import './blog.styles.scss';

const strapi = new Strapi('https://transferable-blog.herokuapp.com');

export default class BlogPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            blogPosts: []
        };
    }

    async componentDidMount() {
        try {
            window.scrollTo(0, 0);
            console.log('calling componentDIDMOUNT');

            const blogPosts = await strapi.getEntries('blogposts');

            blogPosts.forEach((post) => {
                post['formattedDate'] = formatBlogPostTimestamp(post.updatedAt);
            });

            this.setState({
                loading: false,
                blogPosts,
            });
        } catch (err) {
            alert(err);
        }
    }

    render() {
        const { blogPosts, loading } = this.state;

        return (
            <Layout currentPath="/blog">
                <SEO title="Blog" />
                <div className="blog-page">
                    <div className="blog-page__preview-content-container">
                        {
                            !loading ?
                                <BlogPostsPreview blogPosts={blogPosts} /> :
                                    <BlogPostsLoading />
                        }
                    </div>
                </div>
            </Layout>
        );
    }
}
